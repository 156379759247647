import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FertilityTestDto, CreateFertilityTestDto, UpdateFertilityTestDto } from '@bemum/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FertilityTestService {
  constructor(private http: HttpClient) {}
  getList(
    bointerface?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortField?: string,
    sortOrder?: string,
    textFilter?: string,
    categoryFilters?: any[],
    statusFilters?: any[],
    programFilters?: any[]
  ): Observable<FertilityTestDto[]> {
    let url = `${environment.api_url}/fertility-test`;
    if (bointerface) url += `?bointerface=${bointerface}`;
    if (pageIndex) url += `&pageIndex=${pageIndex}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (categoryFilters && categoryFilters.length) url += `&categoryFilters=${categoryFilters}`;
    if (statusFilters && statusFilters.length) url += `&statusFilters=${statusFilters}`;
    if (programFilters && programFilters.length) url += `&programFilters=${programFilters}`;
    if (sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      url += `&sortOrder=${order}`;
    }
    if (textFilter) {
      url += `&textFilter=${textFilter}`;
    }
    return this.http.get<FertilityTestDto[]>(url);
  }

  get(id: string) {
    return this.http.get<FertilityTestDto>(`${environment.api_url}/fertility-test/${id}`);
  }

  update(id: string, data: UpdateFertilityTestDto) {
    return this.http.patch<UpdateFertilityTestDto>(`${environment.api_url}/fertility-test/${id}`, data);
  }

  post(data: FertilityTestDto) {
    return this.http.post<CreateFertilityTestDto>(`${environment.api_url}/fertility-test`, data);
  }

  delete(id: string) {
    return this.http.delete<FertilityTestDto>(`${environment.api_url}/fertility-test/${id}`);
  }

  put(id: string, category: string) {
    return this.http.put<UpdateFertilityTestDto>(`${environment.api_url}/fertility-test/${id}`, { category });
  }
}
