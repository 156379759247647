import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReportType } from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private http: HttpClient) {}

  downloadReport(reportType: ReportType) {
    return this.http.get<void>(`${environment.api_url}/analytics/${reportType}`);
  }
}
