import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  AlimDto,
  CiqualIngredientCompoDto,
  CnfIngredientCompoDto,
  CreateIngredientDto,
  FoodNameDto,
  FoodReferenceSource,
  IngredientDto,
} from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class IngredientService {
  constructor(private http: HttpClient) {}

  getList(
    bointerface?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortField?: string,
    sortOrder?: string,
    textFilter?: string,
    tagFilters?: number[]
  ): Observable<IngredientDto[]> {
    let url = `${environment.api_url}/ingredients`;
    if (bointerface) url += `?bointerface=${bointerface}`;
    if (pageIndex) url += `&pageIndex=${pageIndex}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      url += `&sortOrder=${order}`;
    }
    if (textFilter) {
      url += `&textFilter=${textFilter}`;
    }
    if (tagFilters && tagFilters.length) {
      url += `&tagFilters=${tagFilters}`;
    }
    return this.http.get<IngredientDto[]>(url);
  }

  get(id: number) {
    return this.http.get<IngredientDto>(`${environment.api_url}/ingredients/${id}`);
  }

  post(data: CreateIngredientDto): Observable<IngredientDto> {
    return this.http.post<IngredientDto>(`${environment.api_url}/ingredients`, data);
  }

  update(id: number, data: CreateIngredientDto): Observable<IngredientDto> {
    return this.http.patch<IngredientDto>(`${environment.api_url}/ingredients/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${environment.api_url}/ingredients/${id}`);
  }

  listFoodSource(foodSource: FoodReferenceSource) {
    return this.http.get<AlimDto[] | FoodNameDto[]>(`${environment.api_url}/ingredients/source/${foodSource}`);
  }

  getFoodSource(foodSource: FoodReferenceSource, foodSourceId: number) {
    return this.http.get<CiqualIngredientCompoDto | CnfIngredientCompoDto>(
      `${environment.api_url}/ingredients/source/${foodSource}/${foodSourceId}`
    );
  }
}
