import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateUnitDto, UnitDto, UpdateUnitDto } from '@bemum/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(private http: HttpClient) {}

  post(data: CreateUnitDto): Observable<UnitDto> {
    return this.http.post<UnitDto>(`${environment.api_url}/units`, data);
  }

  patch(id: number, data: UpdateUnitDto): Observable<UnitDto> {
    return this.http.patch<UnitDto>(`${environment.api_url}/units/${id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${environment.api_url}/units/${id}`);
  }
}
