import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthentificationService } from '../../core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authentificationService: AuthentificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authentificationService.accessToken;
    if (token) {
      const currentUser = this.authentificationService.currentUser;
      if (route.data.roles && !route.data.roles.includes(currentUser.role)) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
