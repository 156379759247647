import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { PendingChangesRecipeGuard } from './guards/pending-changes-recipe.guard';
import { PendingChangesUnitFamilyGuard } from './guards/pending-changes-unit-family.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { HttpTokenInterceptor } from './interceptors';
import {
  AuthentificationService,
  DeliveryService,
  IngredientService,
  MealPlanningService,
  MetricsService,
  ObservanceService,
  PatientsService,
  RecipeService,
  ReviewService,
  StaffService,
  SurveyService,
  AnalyticsService,
  TagService,
  SupermarketAisleService,
  UnitFamilyService,
  UnitService,
} from './services';

@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  providers: [
    AuthentificationService,
    PatientsService,
    SurveyService,
    DeliveryService,
    StaffService,
    MealPlanningService,
    IngredientService,
    TagService,
    SupermarketAisleService,
    UnitFamilyService,
    UnitService,
    RecipeService,
    ReviewService,
    MetricsService,
    ObservanceService,
    AnalyticsService,
    AuthGuard,
    NoAuthGuard,
    PendingChangesGuard,
    PendingChangesUnitFamilyGuard,
    PendingChangesRecipeGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
