import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaffDto, UpdateStaffDto } from '@bemum/api-interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get<StaffDto[]>(`${environment.api_url}/staff`);
  }

  get(id: string) {
    return this.http.get<StaffDto>(`${environment.api_url}/staff/${id}`);
  }

  update(id: string, data: UpdateStaffDto) {
    return this.http.patch<UpdateStaffDto>(`${environment.api_url}/staff/${id}`, data);
  }

  post(data: StaffDto) {
    return this.http.post<StaffDto>(`${environment.api_url}/staff`, data);
  }

  delete(id: string) {
    return this.http.delete<StaffDto>(`${environment.api_url}/staff/${id}`);
  }
}
