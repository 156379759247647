import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreateRecipeDto, RecipeDto, UpdateRecipeDto } from '@bemum/api-interfaces';
import { HttpEvent } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  constructor(private http: HttpClient) {}

  getList(
    bointerface?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortField?: string,
    sortOrder?: string,
    textFilter?: string,
    tagFilters?: number[]
  ): Observable<RecipeDto[]> {
    let url = `${environment.api_url}/recipes`;
    if (bointerface) url += `?bointerface=${bointerface}`;
    if (pageIndex) url += `&pageIndex=${pageIndex}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      url += `&sortOrder=${order}`;
    }
    if (textFilter) {
      url += `&textFilter=${textFilter}`;
    }
    if (tagFilters && tagFilters.length) {
      url += `&tagFilters=${tagFilters}`;
    }
    return this.http.get<RecipeDto[]>(url);
  }

  post(data: CreateRecipeDto): Observable<RecipeDto> {
    return this.http.post<RecipeDto>(`${environment.api_url}/recipes`, data);
  }

  get(id: string): Observable<RecipeDto> {
    return this.http.get<RecipeDto>(`${environment.api_url}/recipes/${id}`);
  }

  update(id: number, data: UpdateRecipeDto): Observable<RecipeDto> {
    return this.http.patch<RecipeDto>(`${environment.api_url}/recipes/${id}`, data);
  }

  delete(id: string) {
    return this.http.delete(`${environment.api_url}/recipes/${id}`);
  }

  uploadImage(formData: FormData): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${environment.api_url}/recipes/image`, formData, {
      reportProgress: true,
      withCredentials: false,
    });
    return this.http.request<HttpEvent<any>>(req);
  }
}
