import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateReviewDto, ReviewDto, UpdateReviewDto } from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private http: HttpClient) {}

  getList(patientId: string) {
    return this.http.get<ReviewDto[]>(`${environment.api_url}/patients/${patientId}/reviews`);
  }

  create(data: CreateReviewDto) {
    return this.http.post<ReviewDto>(`${environment.api_url}/review`, data);
  }

  update(id: string, data: UpdateReviewDto) {
    return this.http.put<ReviewDto>(`${environment.api_url}/review/${id}`, data);
  }
}
