import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientToolsheetsDto, ToolsheetsDto, UpdateAccessToolsheetsDto } from '@bemum/api-interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ToolsheetsService {
  constructor(private http: HttpClient) {}

  getList(
    bointerface?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortField?: string,
    sortOrder?: string,
    textFilter?: string,
    filterToolsheets?: any[]
  ) {
    let url = `${environment.api_url}/toolsheets`;
    if (bointerface) url += `?bointerface=${bointerface}`;
    if (pageIndex) url += `&pageIndex=${pageIndex}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (filterToolsheets) url += `&filterToolsheets=${filterToolsheets}`;
    if (sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      url += `&sortOrder=${order}`;
    }
    if (textFilter) {
      url += `&textFilter=${textFilter}`;
    }
    return this.http.get<ToolsheetsDto[]>(url);
  }

  get(id: string) {
    return this.http.get<ToolsheetsDto>(`${environment.api_url}/toolsheets/${id}`);
  }

  getListByPatient(patientId: string) {
    return this.http.get<PatientToolsheetsDto[]>(`${environment.api_url}/patients/${patientId}/toolsheets`);
  }

  updateAccess(patientId: string, toolsheetId: string, hasAccess: boolean) {
    const access: UpdateAccessToolsheetsDto = { hasAccess };
    return this.http.patch<PatientToolsheetsDto[]>(
      `${environment.api_url}/patients/${patientId}/toolsheets/${toolsheetId}`,
      access
    );
  }
  update(id: string, data: ToolsheetsDto) {
    return this.http.patch<ToolsheetsDto>(`${environment.api_url}/toolsheets/${id}`, data);
  }

  post(data: ToolsheetsDto) {
    return this.http.post<ToolsheetsDto>(`${environment.api_url}/toolsheets`, data);
  }

  delete(id: string) {
    return this.http.delete<ToolsheetsDto>(`${environment.api_url}/toolsheets/${id}`);
  }
}
