import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DailyMealPlanningDto, GenerateDailyMealPlanningsManuallyDto, UpdateMealPlanningDto } from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class MealPlanningService {

  constructor(private http: HttpClient) { }

  update(id: string, data: DailyMealPlanningDto) {
    return this.http
      .patch<UpdateMealPlanningDto>(`${environment.api_url}/meal-planner/${id}`, data)
  }

  get(date: string, patientId: string) {
    return this.http
      .get<DailyMealPlanningDto>(`${environment.api_url}/meal-planner/?date=${date}&patientId=${patientId}`)
  }

  post(data: GenerateDailyMealPlanningsManuallyDto) {
    return this.http
      .post<GenerateDailyMealPlanningsManuallyDto>(`${environment.api_url}/meal-planner`, data)
  }

  UpdateSuitableRecipes(id: string, mealToSwitch: string, data: { id: number, name: string }) {
    return this.http
      .patch(`${environment.api_url}/meal-planner/${id}/suitable-recipes/${mealToSwitch} `, data)
  }

  getSuitableMealPlanning(id: string, mealToSwitch: string) {
    return this.http
      .get<DailyMealPlanningDto>(`${environment.api_url}/meal-planner/${id}/suitable-recipes/${mealToSwitch}`)
  }
}
