import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateTagDto, TagDto } from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) {}

  getList() {
    return this.http.get<TagDto[]>(`${environment.api_url}/tags`);
  }

  post(data: CreateTagDto) {
    return this.http.post<CreateTagDto>(`${environment.api_url}/tags`, data);
  }

  get(id: string) {
    return this.http.get<TagDto>(`${environment.api_url}/tags/${id}`);
  }

  update(id: string, data: CreateTagDto) {
    return this.http.patch<CreateTagDto>(`${environment.api_url}/tags/${id}`, data);
  }

  delete(id: string) {
    return this.http.delete(`${environment.api_url}/tags/${id}`);
  }
}
