import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateHealthSurveyDto,
  CreateMemorySurveyDto,
  CreateMoodSurveyDto,
  CreateOxidativeSurveyDto,
  CreatePhysicalActivitySurveyDto,
  CreateProfileSurveyDto,
  CreateReproductiveSurveyDto,
  CreateSleepSurveyDto,
  CreateStressSurveyDto,
  FoodPreferenceSurveyDto,
  HealthSurveyDto,
  MemorySurveyDto,
  MoodSurveyDto,
  OxidativeSurveyDto,
  PhysicalActivitySurveyDto,
  ProfileSurveyDto,
  ReproductiveSurveyDto,
  SleepSurveyDto,
  StressSurveyDto,
  UpdateHealthSurveyDto,
  UpdateMemorySurveyDto,
  UpdateMoodSurveyDto,
  UpdateOxidativeSurveyDto,
  UpdatePhysicalActivitySurveyDto,
  UpdateProfileSurveyDto,
  UpdateReproductiveSurveyDto,
  UpdateSleepSurveyDto,
  UpdateStressSurveyDto,
} from '@bemum/api-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

type UpdateSignupSurveyDtoU =
  | UpdateProfileSurveyDto
  | UpdateHealthSurveyDto
  | UpdateMemorySurveyDto
  | UpdateMoodSurveyDto
  | UpdateStressSurveyDto
  | UpdateSleepSurveyDto
  | UpdateOxidativeSurveyDto
  | UpdateReproductiveSurveyDto
  | UpdatePhysicalActivitySurveyDto;

type UpdateSignupSurveyDTO<T extends UpdateSignupSurveyDtoU> = T extends UpdateProfileSurveyDto
  ? ProfileSurveyDto
  : T extends UpdateHealthSurveyDto
  ? HealthSurveyDto
  : T extends UpdateMemorySurveyDto
  ? MemorySurveyDto
  : T extends UpdateMoodSurveyDto
  ? MoodSurveyDto
  : T extends UpdateStressSurveyDto
  ? StressSurveyDto
  : T extends UpdateSleepSurveyDto
  ? SleepSurveyDto
  : T extends UpdateOxidativeSurveyDto
  ? OxidativeSurveyDto
  : T extends UpdateReproductiveSurveyDto
  ? ReproductiveSurveyDto
  : T extends UpdatePhysicalActivitySurveyDto
  ? PhysicalActivitySurveyDto
  : never;

export type SurveyDtoU =
  | ProfileSurveyDto
  | HealthSurveyDto
  | MoodSurveyDto
  | MemorySurveyDto
  | StressSurveyDto
  | SleepSurveyDto
  | OxidativeSurveyDto
  | ReproductiveSurveyDto
  | PhysicalActivitySurveyDto;

export enum SurveyType {
  PROFILE = 'profile',
  HEALTH = 'health',
  MOOD = 'mood',
  MEMORY = 'memory',
  STRESS = 'stress',
  SLEEP = 'sleep',
  OXIDATIVE = 'oxidative',
  REPRODUCTIVE = 'reproductive',
  PHYSICAL_ACTIVITY = 'physical-activity',
}

type CreateSignupSurveyDtoU =
  | CreateProfileSurveyDto
  | CreateHealthSurveyDto
  | CreateMoodSurveyDto
  | CreateMemorySurveyDto
  | CreateStressSurveyDto
  | CreateSleepSurveyDto
  | CreateOxidativeSurveyDto
  | CreateReproductiveSurveyDto
  | CreatePhysicalActivitySurveyDto;

type CreateSignupSurveyDTO<T extends CreateSignupSurveyDtoU> = T extends CreateProfileSurveyDto
  ? ProfileSurveyDto
  : T extends CreateHealthSurveyDto
  ? HealthSurveyDto
  : T extends CreateMoodSurveyDto
  ? MoodSurveyDto
  : T extends CreateMemorySurveyDto
  ? MemorySurveyDto
  : T extends CreateStressSurveyDto
  ? StressSurveyDto
  : T extends CreateSleepSurveyDto
  ? SleepSurveyDto
  : T extends CreateOxidativeSurveyDto
  ? OxidativeSurveyDto
  : T extends CreateReproductiveSurveyDto
  ? ReproductiveSurveyDto
  : T extends CreatePhysicalActivitySurveyDto
  ? PhysicalActivitySurveyDto
  : never;

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private http: HttpClient) {}

  readFollowUpForms<T extends SurveyDtoU>(patientId: string, type: SurveyType): Observable<T[]> {
    return this.http.get<T[]>(`${environment.api_url}/survey/follow-up/${type}/history?patientId=${patientId}`);
  }

  readSignupForm<T extends SurveyDtoU>(patientId: string, type: SurveyType): Observable<T> {
    return this.http.get<T>(`${environment.api_url}/survey/sign-up/${type}?patientId=${patientId}`);
  }

  writeSignupForm<T extends UpdateSignupSurveyDtoU>(
    type: SurveyType,
    payload: T,
    patientId: string,
    action: 'post' | 'put'
  ): Observable<UpdateSignupSurveyDTO<T>> {
    if (action === 'post') {
      return this.http.post<UpdateSignupSurveyDTO<T>>(
        `${environment.api_url}/survey/sign-up/${type}`,
        Object.assign({ patient: patientId }, payload)
      );
    }
    return this.http.put<UpdateSignupSurveyDTO<T>>(
      `${environment.api_url}/survey/sign-up/${type}?patientId=${patientId}`,
      payload
    );
  }

  getSignupHealthForm(patientId: string) {
    return this.http.get<HealthSurveyDto>(`${environment.api_url}/survey/sign-up/health?patientId=${patientId}`);
  }

  getSignupForm(surveyId: string, patientId: string) {
    return this.http.get<SurveyDtoU>(`${environment.api_url}/survey/sign-up/${surveyId}?patientId=${patientId}`).pipe(
      map((data: SurveyDtoU) => {
        return data;
      })
    );
  }

  postSignupForm(surveyId: string, form: CreateSignupSurveyDtoU): Observable<SurveyDtoU> {
    return this.http.post(`${environment.api_url}/survey/sign-up/${surveyId}`, form).pipe(
      map((data: SurveyDtoU) => {
        return data;
      })
    );
  }

  updateSignupForm(surveyId: string, patientId: string, form: CreateSignupSurveyDtoU): Observable<SurveyDtoU> {
    return this.http.patch(`${environment.api_url}/survey/sign-up/${surveyId}?patientId=${patientId}`, form).pipe(
      map((data: SurveyDtoU) => {
        return data;
      })
    );
  }

  postFoodPreferenceForm(form: CreateSignupSurveyDtoU): Observable<SurveyDtoU> {
    return this.http.post(`${environment.api_url}/survey/food-preference`, form).pipe(
      map((data: SurveyDtoU) => {
        return data;
      })
    );
  }

  getFoodPreferenceForm(patientId: string): Observable<FoodPreferenceSurveyDto> {
    return this.http.get(`${environment.api_url}/survey/food-preference?patientId=${patientId}`).pipe(
      map((data: FoodPreferenceSurveyDto) => {
        return data;
      })
    );
  }
}
