import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientObservanceListDto } from '@bemum/api-interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ObservanceService {
  constructor(private http: HttpClient) {}

  getObservanceList(patientId: string) {
    return this.http.get<PatientObservanceListDto>(`${environment.api_url}/observance?patientId=${patientId}`);
  }
}
