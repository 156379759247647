import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreateJwtRequest, CreateJwtResponse, ForgotPasswordRequest, StaffDto } from '@bemum/api-interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StaffService } from './staff.service';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  /** Holds and emits the logged in user */
  private currentUserSubject: BehaviorSubject<StaffDto> = null;
  /** Subscribe to this property to get the logged in user and future updates */
  public currentUser$: Observable<StaffDto> = null;
  /** The currently logged in user */
  public get currentUser() {
    return this.currentUserSubject.value;
  }

  private accessTokenSubject: BehaviorSubject<string> = null;
  public accessToken$: Observable<string> = null;
  public get accessToken() {
    return this.accessTokenSubject.value;
  }

  private refreshTokenSubject: BehaviorSubject<string> = null;
  public refreshToken$: Observable<string> = null;
  public get refreshToken() {
    return this.refreshTokenSubject.value;
  }

  /** Loads the session data from localStorage */
  constructor(private http: HttpClient, private staffService: StaffService, private router: Router) {
    const staff = localStorage.getItem('staff');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    this.currentUserSubject = new BehaviorSubject<StaffDto>(JSON.parse(staff));
    this.currentUser$ = this.currentUserSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<string>(accessToken);
    this.accessToken$ = this.accessTokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(refreshToken);
    this.refreshToken$ = this.refreshTokenSubject.asObservable();
  }

  login(credentials: CreateJwtRequest) {
    return this.http.post<CreateJwtResponse>(`${environment.api_url}/authentication/staff/jwt`, credentials).pipe(
      switchMap((data: CreateJwtResponse) => {
        const staffId = JSON.parse(atob(data.accessToken.split('.')[1])).sub;

        this.accessTokenSubject.next(data.accessToken);
        localStorage.setItem('accessToken', data.accessToken);

        this.refreshTokenSubject.next(data.refreshToken);
        localStorage.setItem('refreshToken', data.refreshToken);

        this.staffService.get(staffId).subscribe((staff) => {
          this.currentUserSubject.next(staff);
          localStorage.setItem('staff', JSON.stringify(staff));
        });

        return of(data);
      })
    );
  }

  refresh() {
    const token = this.refreshToken;
    if (!token) {
      throw new Error('Refresh token not found');
    }

    return this.http
      .post<CreateJwtResponse>(`${environment.api_url}/authentication/staff/refresh-jwt`, {
        refreshToken: token,
      })
      .pipe(
        switchMap((data: CreateJwtResponse) => {
          const staffId = JSON.parse(atob(data.accessToken.split('.')[1])).sub;

          this.accessTokenSubject.next(data.accessToken);
          localStorage.setItem('accessToken', data.accessToken);

          this.refreshTokenSubject.next(data.refreshToken);
          localStorage.setItem('refreshToken', data.refreshToken);

          this.staffService.get(staffId).subscribe((staff) => {
            this.currentUserSubject.next(staff);
            localStorage.setItem('staff', JSON.stringify(staff));
          });

          return of(data);
        })
      );
  }

  /**
   * Cleanup session and redirect the user to the login page
   * @see https://api.bemum.co/docs#operation/staff-jwt-revoke
   */
  logout() {
    // Call to authentification api to revoke refreshToken
    const staffId = this.currentUser?.id;

    if (!this.currentUser) {
      window.localStorage.clear();
      this.accessTokenSubject.next(null);
      this.refreshTokenSubject.next(null);
      this.currentUserSubject.next(null);
      this.router.navigateByUrl('/login');
      return;
    }

    return this.http.delete(`${environment.api_url}/authentication/staff/${staffId}/refreshToken`).pipe(
      switchMap((data) => {
        window.localStorage.clear();
        this.accessTokenSubject.next(null);
        this.refreshTokenSubject.next(null);
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/login');
        return of(data);
      })
    );
  }

  /** @see https://api.bemum.co/docs#operation/staff-password-reset */
  forgotPassword(credentials: ForgotPasswordRequest) {
    return this.http.post(`${environment.api_url}/authentication/staff/forgot-password`, credentials);
  }

  /** @see https://api.bemum.co/docs#operation/staff-password-reset */
  resetPassword(token: string, password: string) {
    return this.http.patch(
      `${environment.api_url}/authentication/staff/reset-password`,
      { password: password.trim() },
      {
        headers: new HttpHeaders({
          'X-Reset-Token': token,
        }),
      }
    );
  }

  /** @todo change route to specific one not regenerating a token ? */
  checkCurrentPassword(credentials: CreateJwtRequest): Observable<CreateJwtResponse> {
    return this.http.post(`${environment.api_url}/authentication/staff/jwt`, credentials).pipe(
      map((data: CreateJwtResponse) => {
        return data;
      })
    );
  }
}
