import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { ModalButtonOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { IngredientStatus } from '@bemum/api-interfaces';
import { IngredientDetailComponent } from '../../ingredient/ingredient-detail/ingredient-detail.component';

/**
 * This guard is used by IngredientDetailComponent
 * to prevent accidental loss of user data when
 * navigating away or closing the page
 */
@Injectable()
export class PendingChangesGuard implements CanDeactivate<IngredientDetailComponent> {
  private modal: NzModalRef;

  // eslint-disable-next-line no-empty-function
  constructor(private modalService: NzModalService) {}

  canDeactivate(component: IngredientDetailComponent) {
    if (component.canDeactivate()) {
      return true;
    }

    const modal = this.createModal(component);
    return modal.afterClose.asObservable() as Observable<boolean>;
  }

  createModal(component: IngredientDetailComponent) {
    let footer: Array<ModalButtonOptions<NzSafeAny>> = [
      {
        label: 'Quitter',
        type: 'default',
        onClick: () => this.modal.destroy(true),
      },
      {
        label: 'Enregistrer le brouillon',
        type: 'default',
        onClick: () => {
          component.saveAsDraft(null);
          this.modal.destroy(true);
        },
      },
      { label: 'Continuer la modification', type: 'primary', onClick: () => this.modal.destroy(false) },
    ];

    if (component.ingredient?.status === IngredientStatus.TO_PUBLISH) {
      footer = [footer[0], footer[2]];
    }
    this.modal = this.modalService.create({
      nzTitle: 'Attention',
      nzContent: "Les données n'ont pas été enregistrées. Souhaitez-vous quitter la page ?",
      nzFooter: footer,
    });
    return this.modal;
  }
}
