import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssessmentSurveyDto, NutrinomeDto, PatientDto, UpdatePatientDto } from '@bemum/api-interfaces';
import { environment } from '../../../environments/environment';
import { Patient } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  /**
   * Subject used to store selected patient Id
   * represents patientId in params
   */
  private patientIdSubject: BehaviorSubject<string>;
  /**
   * Observable used to cast selected patient Id
   * represents patientId in params
   */
  public patientId: Observable<string>;

  /**
   * Subject used to store isTestMode boolean
   */
  private isTestModeSubject: BehaviorSubject<boolean>;
  /**
   * Observable used to cast isTestMode boolean
   */
  public isTestMode: Observable<boolean>;

  /**
   * Subject used to store isArchivedMode boolean
   */
  private isArchivedModeSubject: BehaviorSubject<boolean>;
  /**
   * Observable used to cast isArchivedMode boolean
   */
  public isArchivedMode: Observable<boolean>;

  constructor(private http: HttpClient) {
    this.patientIdSubject = new BehaviorSubject<string>(window.localStorage.getItem('patientId'));
    this.patientId = this.patientIdSubject.asObservable();
    // isTestMode is false by default
    this.isTestModeSubject = new BehaviorSubject<boolean>(false);
    this.isTestMode = this.isTestModeSubject.asObservable();
    // isArchivedMode is false by default
    this.isArchivedModeSubject = new BehaviorSubject<boolean>(false);
    this.isArchivedMode = this.isArchivedModeSubject.asObservable();
  }

  recordSelectedPatient(id: string) {
    window.localStorage['patientId'] = id;
    this.patientIdSubject.next(id);
  }

  get(id: string) {
    return this.http.get<PatientDto>(`${environment.api_url}/patients/${id}`);
  }

  getList() {
    const isArchived = this.isArchivedModeSubject.getValue();
    return this.http.get<PatientDto[]>(`${environment.api_url}/patients`);
  }

  getPatientsList(
    bointerface?: boolean,
    pageIndex?: number,
    pageSize?: number,
    textFilter?: string,
    isArchived?: string,
    isTestMode?: string,
    sortField?: string,
    sortOrder?: string,
    filterStaff?: string
  ) {
    let url = `${environment.api_url}/patients`;
    if (bointerface) url += `?bointerface=${bointerface}`;
    if (pageIndex) url += `&pageIndex=${pageIndex}`;
    if (pageSize) url += `&pageSize=${pageSize}`;
    if (textFilter) url += `&textFilter=${textFilter}`;
    if (isArchived) url += `&isArchived=${isArchived}`;
    if (sortField) url += `&sortField=${sortField}`;
    if (sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      url += `&sortOrder=${order}`;
    }
    if (isTestMode) url += `&group=${isTestMode}`;
    if (filterStaff) url += `&filterStaff=${filterStaff}`;

    return this.http.get<PatientDto[]>(url);
  }

  patch(id: string, data: UpdatePatientDto) {
    return this.http.patch<UpdatePatientDto>(`${environment.api_url}/patients/${id}`, data);
  }

  assignStaff(patientId: string, staffId: string) {
    return this.http.put<Patient>(`${environment.api_url}/patients/${patientId}/staff`, { staffId });
  }

  getBillingStatement(patientId: string) {
    return this.http.get(`${environment.api_url}/patients/${patientId}/billing-statement`, {
      responseType: 'blob',
    });
  }

  getNutrinomeHistory(patientId: string, options?: GetNutrinomeOptions) {
    let query: string;
    let endpoint = `${environment.api_url}/patients/${patientId}/nutrinomes`;

    if (options) {
      query = Object.keys(options)
        .map((key) => `${key}=${options[key]}`)
        .join('&');
      endpoint += `?${query}`;
    }

    return this.http.get<NutrinomeDto[]>(endpoint);
  }

  getNutritionAssessment(id: string) {
    return this.http.get<AssessmentSurveyDto[]>(`${environment.api_url}/patients/${id}/nutrion-assessments`);
  }

  setTestMode(isTestMode: boolean) {
    this.isTestModeSubject.next(isTestMode);
  }

  setArchivedMode(isArchivedMode: boolean) {
    this.isArchivedModeSubject.next(isArchivedMode);
  }

  resetPatient(id: string) {
    return this.http.delete(`${environment.api_url}/patients/${id}/all`);
  }

  exportPatientsList() {
    return this.http.get(`${environment.api_url}/patients/export/list`, { responseType: 'blob' });
  }
}

export interface GetNutrinomeOptions {
  order_by_date?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
}
