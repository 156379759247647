import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private http: HttpClient) {}

  notifyNewPodcastEpisod() {
    return this.http.post(`${environment.api_url}/push-notification/podcast`, null);
  }
}
