import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateDeliveryEventDto,
  DeliveryDto,
  DeliveryEventDto,
  PatientGroup,
  Status,
  UpdateDeliveryDto,
} from '@bemum/api-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private http: HttpClient) {}

  getPatientDeliveries(patientId: string): Observable<DeliveryDto[]> {
    return this.http.get<DeliveryDto[]>(`${environment.api_url}/patients/${patientId}/deliveries`);
  }

  getList(isTestMode: boolean, isArchivedMode: boolean, status?: Status, page?: number): Observable<DeliveryDto[]> {
    let url = `${environment.api_url}/delivery?`;
    url += isTestMode ? `patientGroup=${PatientGroup.TEST}` : `patientGroup=${PatientGroup.LIVE}`;
    url += `&archivedPatients=${isArchivedMode}`;
    if (status) url += `&status=${status}`;
    if (page) url += `&page=${page}`;

    return this.http.get(url).pipe(
      map((data: DeliveryDto[]) => {
        return data;
      })
    );
  }

  createDeliveryEvent(deliveryId: string, options: CreateDeliveryEventDto) {
    return this.http.post<DeliveryEventDto>(`${environment.api_url}/delivery/${deliveryId}/events`, options);
  }

  cancel(id: string) {
    return this.http.patch(`${environment.api_url}/delivery/${id}`, {
      status: 'CANCELED',
    });
  }
}
