/**
 * For each HTTP request
 *     Append the accessToken to it
 *     Intercept a 401 error response
 *         if there is any current refresh process ongoing
 *             wait for it to complete
 *             if it fails
 *                 abandon request
 *             else
 *                 append the new tokens
 *         else
 *             Refresh the tokens
 *             Intercept a 401 error response
 *                 log out
 *             retry with the initial request with new tokens
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateJwtResponse } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { AuthentificationService } from '../services';

enum State {
  IDLE,
  REFRESHING,
  REFRESH_FAILED,
  REFRESH_SUCCESS,
}

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private stateSubject = new BehaviorSubject<State>(State.IDLE);

  constructor(private authentificationService: AuthentificationService, private notification: NzNotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authentificationService.accessToken;

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          if (!this.authentificationService.currentUser) return this.handleRefreshError(error);
        }

        if (error.status !== 401) {
          return throwError(() => {
            return new HttpErrorResponse(error);
          });
        }

        if (error.url.includes('/refresh-jwt')) {
          return this.handleRefreshError(error);
        }

        if (['/jwt', '/reset-password'].every((item) => !error.url.includes(item))) {
          return this.handle401Error(request, next);
        }

        return throwError(() => {
          return new HttpErrorResponse(error);
        });
      })
    );
  }

  /** Append the Authorization header to the outgoing request */
  private addToken(request: HttpRequest<any>, token: string) {
    // Specific treatment for request for uploading recipe images
    const uploadImage = request.url.includes('recipes/image');

    const newRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });

    if (!uploadImage) {
      newRequest.headers.append('Content-Type', 'application/json');
    }
    return newRequest;
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.stateSubject.value === State.IDLE) {
      this.stateSubject.next(State.REFRESHING);
      return this.authentificationService.refresh().pipe(
        switchMap((res: CreateJwtResponse) => {
          this.stateSubject.next(State.REFRESH_SUCCESS);
          this.stateSubject.next(State.IDLE);
          return next.handle(this.addToken(request, res.accessToken));
        }),
        catchError((err) => {
          this.stateSubject.next(State.REFRESH_FAILED);
          this.stateSubject.next(State.IDLE);
          return throwError(err);
        })
      );
    }

    return this.stateSubject.pipe(
      filter((state) => state === State.REFRESH_SUCCESS || state === State.REFRESH_FAILED),
      switchMap((state) => {
        if (state === State.REFRESH_SUCCESS) {
          return next.handle(this.addToken(request, this.authentificationService.accessToken));
        }
        return next.handle(request);
      })
    );
  }

  handleRefreshError(error: HttpErrorResponse) {
    this.notification.error('Votre session a expiré', 'Veuillez-vous reconnecter à nouveau.');
    return this.authentificationService.logout().pipe(switchMap(() => EMPTY));
  }
}
