import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateReviewFtDto, ReviewFtDto, UpdateReviewFtDto } from '@bemum/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReviewFtService {
  constructor(private http: HttpClient) {}

  getList(fertilityTestId: string) {
    return this.http.get<ReviewFtDto[]>(`${environment.api_url}/fertility-test/${fertilityTestId}/reviews`);
  }

  create(data: CreateReviewFtDto) {
    return this.http.post<ReviewFtDto>(`${environment.api_url}/review-ft`, data);
  }

  update(id: string, data: UpdateReviewFtDto) {
    return this.http.put<ReviewFtDto>(`${environment.api_url}/review-ft/${id}`, data);
  }

  delete(id: string) {
    return this.http.delete<ReviewFtDto>(`${environment.api_url}/review-ft/${id}`);
  }
}
