import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetricDto, PatientMetricsDto } from '@bemum/api-interfaces';
import { environment } from '../../../environments/environment';

export enum MetricNames {
  WEIGHT = 'WEIGHT',
  BMI = 'BMI',
  WAIST = 'WAIST',
  HIPS = 'HIPS',
  PAL = 'PAL',
}

export interface GetMetricsOptions {
  /** name of the desire */
  include: MetricNames[];
  order_by_date?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
}

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  constructor(private http: HttpClient) {}

  getMetrics(patientId: string, options: GetMetricsOptions) {
    const query = Object.keys(options)
      .map((key) => `${key}=${Array.isArray(options[key]) ? options[key].join(',') : options[key]}`)
      .join('&');
    return this.http.get<PatientMetricsDto>(`${environment.api_url}/patients/${patientId}/metrics?${query}`);
  }
}
